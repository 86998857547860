<template>
    <div class="content_wpr" v-if="planStep == 1" id="week-setting-section">
        <slot></slot>
        <quote-loader class="section_content w-100 open_area" v-if="planWeekLoader" />
        <div class="section_content w-100 open_area" v-show="!planWeekLoader">
            <div class="addblock_section" v-click-outside="closeWeekTypes">
                <button type="button" class="add_btn pointer ml-auto mr-auto" @click="newWeek = !newWeek;">
                    <i :class="createPlanWeekLoader && !addSmartWeek ? 'fa fa-spin fa-spinner' : 'fas fa-plus'"></i>{{ createPlanWeekLoader && !addSmartWeek ? 'Adding' : 'Add A Week'}}
                </button>
                <div class="add_types" v-if="newWeek">
                    <div class="title">Select Week Type</div>
                    <ul>
                        <li @click="createNewWeek('blank')">
                            <div class="card_item">
                                <img src="@/assets/images/template.svg" alt="">Blank Week
                            </div>
                        </li>
                        <li @click="createNewWeek('smart')">
                            <div class="card_item">
                                <img src="@/assets/images/template.svg" alt="">Smart Week
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- Week List -->
            <Form v-slot="{ errors }" ref="workout-plan-week-form" class="workout-plan-week-form">
                <draggable v-model="planWeeks" tag="ul" :animation="200" item-key="id" handle=".drag-handler" class="week_list" @change="handleSortPlanWeeks()">
                    <template #item="{ element: week, index : w }">
                        <li @click="selectedPlanWeek = week;">
                            <div class="section_header" :class="{ active: selectedPlanWeek.id == week.id && editWeekName}">
                                <div class="handle-cat-drag">
                                    <i class="fas fa-arrows-alt handle-cat-drag drag-handler"></i>
                                    <h3 class="sub_header m-0" v-if="!editWeekName || selectedPlanWeek.id != week.id" @click="editWeekName = true; selectedPlanWeek = week;">
                                        {{ week.name }}
                                    </h3>
                                    <input type="text" v-model="week.name" v-show="editWeekName && selectedPlanWeek.id == week.id">
                                    <span class="save_btn" @click="handleRenameWorkoutPlan(week)" v-show="editWeekName && selectedPlanWeek.id == week.id">Save</span>
                                </div>
                                <div class="week_actions">
                                    <!-- Week Setting -->
                                    <div type="button" class="week_setting">
                                        <span @click="toggleWeekSetting(week)"><i class="fas fa-cog"></i></span>
                                        <div class="dropdown_wpr" :id="`week-setting-wpr-${week.id}`" :class="{ active: selectedPlanWeek.id == week.id && weekSettingDropdown }">
                                            <label :for="`monday_first_${w}`" class="switch_option capsule_btn p-0 mt-2 mb-3 border-0">
                                                <h5 class="large mb-0">Monday is first</h5>
                                                <input type="radio" :id="`monday_first_${w}`" :value="0" v-model="week.is_week_start_sunday" @change="handleWorkoutWeekSetting(week)" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <label :for="`sunday_first_${w}`" class="switch_option capsule_btn p-0 mt-0 mb-3 border-0">
                                                <h5 class="large mb-0">Sunday is first</h5>
                                                <input type="radio" :id="`sunday_first_${w}`" :value="1" v-model="week.is_week_start_sunday" @change="handleWorkoutWeekSetting(week)" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <label :for="`smart_week_${w}`" class="switch_option capsule_btn p-0 mt-0 mb-2 border-0">
                                                <h5 class="large mb-0">Save as Smart Week</h5>
                                                <input type="checkbox" :id="`smart_week_${w}`" :true-value="1" :false-value="0" v-model="week.is_smart_week" @change="!week.is_smart_week || week.smart_week_id ? handleWorkoutWeekSetting(week) : ''" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="form_grp py-1 m-0" v-if="week.is_smart_week == 1 && selectedPlanWeek.id == week.id">
                                                <div class="group_item">
                                                    <label class="input_label">Name</label>
                                                    <div class="field_wpr has_suffix" :class="{ 'has-error': errors.smart_week_name }">
                                                        <Field autocomplete="off" type="text" name="smart_week_name" v-model="week.smart_week_name" placeholder="Smart Week" rules="required" label="smart week name" />
                                                        <span class="suffix" :disabled="smartWeekLoader" @click="handleUpdateSmartWeek(week)"><i class="fa fa-spin fa-spinner" v-if="smartWeekLoader"></i>{{ smartWeekLoader ? ' Saving' : 'Save' }}</span>
                                                    </div>
                                                    <ErrorMessage name="smart_week_name" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" class="up_down" @click="handleDuplicatePlanWeek(week.id)">
                                        <i class="fa fa-spin fa-spinner" v-if="planWeekDuplicateLoader && selectedPlanWeek.id == week.id"></i>
                                        <i class="far fa-copy" v-else></i>
                                    </button>
                                    <button type="button" class="delete_btn" @click="handleDeletePlanWeek(week.id)"><i class="fas fa-trash-alt danger"></i></button>
                                </div>
                            </div>
                            <div class="week_panel">
                                <ul :class="{'narrow' : hasPreview}">
                                    <li v-for="(setting, s) in week.week_settings" :key="s" @click="selectedDayIndex = s; selectedWeekIndex = w;" :class="{ 'has-error': hasError && setting.has_workout == 1 && setting.workouts.length == 0 }">
                                        <h5>
                                            <div class="day_title">
                                                {{ setting.title }}
                                                <label :for="`workout_day_${s}_${w}`" class="switch_option capsule_btn p-0 border-0">
                                                    <input type="checkbox" :id="`workout_day_${s}_${w}`" :name="`plan_week_workout_${s}_${w}`" :true-value="1" :false-value="0" v-model="setting.has_workout" hidden @change="openPlanWeekDaySetting(setting, true, 0)" />
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </h5>
                                        <div class="box_item">
                                            <draggable v-model="setting.workouts" item-key="id" tag="ol" @change="handleSortWeekWorkouts(setting.workouts, week.id)" :animation="200" class="week_list" v-if="setting.has_workout == 1 && setting.workouts.length">
                                                <template #item="{ element : workout, index: wt}">
                                                    <li>
                                                        <label class="week-workout-label" @click="toggleWeekDayWorkoutSetting(w, s, wt)">
                                                            <div class="workout-name" @click="openPlanWeekDaySetting(setting, false)"><label>{{ workout.name }}</label>, <time>{{ workout.period }}</time></div> <a @click="toggleCopyDayWorkout(week, workout)"><i class="far fa-copy"></i></a>
                                                            <div class="quick_info">{{workout.name }}</div>
                                                        </label>
                                                        <div class="week_days_list" :class="{ active: copyWeekWorkout && selectedDayIndex == s && selectedWeekIndex == w && selectedDayWorkoutIndex == wt }">
                                                            <label v-for="(weekDay, wd) in weekDays" :key="wd" :class="{'active' : weekDay.isWorkout}">
                                                                <div class="item_wpr" @click="wd != s ? handleCopyToWeek(workout, w, weekDay) : ''">
                                                                    <span class="weekdays">{{weekDay.title.slice(0, 3)}}</span>
                                                                    <span class="tick_box"></span>
                                                                </div>
                                                            </label>
                                                            <div class="action_wpr">
                                                                <button type="button" class="cancel_btn mr-1" @click="resetWeekplans()">Cancel</button>
                                                                <button type="button" class="okay_btn" @click="handleStoreWeekWorkouts(workout, week)">
                                                                    <i class="fa fa-spin fa-spinner" v-if="workoutCopyInWeekLoader"></i> &nbsp;{{ workoutCopyInWeekLoader ? 'Saving' : 'Okay' }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </template>
                                            </draggable>
                                            <div class="add_workout" v-if="setting.has_workout == 1 && setting.workouts.length >= 0 && setting.workouts.length < 3" @click="openPlanWeekDaySetting(setting)">
                                                <i class="fas fa-plus"></i><span>Add</span>
                                            </div>
                                            <p class="day-workout-error-message" v-if="hasError && setting.has_workout == 1 && setting.workouts.length == 0">Please add workout</p>
                                            <p v-if="setting.has_workout === 0" @ccclick.self="toggleWeekDaySetting(w, s)">
                                                Off
                                                <!-- {{ setting.rest_name ? setting.rest_name : 'Off' }} -->
                                                <!-- <div class="quick_info" v-show="setting.rest_desc">{{ setting.rest_desc ? setting.rest_desc : 'No Information' }}</div>
                                                <div class="dropdown_wpr" :class="{ active: weekDaySettingDropdown && selectedWeekIndex == w && selectedDayIndex == s }" @click="$event.stopPropagation()">
                                                    <div class="form_grp p-0 m-0" v-if="setting.has_workout == 0">
                                                        <div class="group_item">
                                                            <label class="input_label">Rest Day Name</label>
                                                            <div class="field_wpr">
                                                                <Field type="text" v-model="setting.rest_name" :name="`plan_day_rest_${s}_${w}`" @input="handleUpdateInputWeekDaySetting(setting)" />
                                                            </div>
                                                            <ErrorMessage :name="`plan_day_rest_${s}_${w}`" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp pt-2 pb-0 m-0" v-if="setting.has_workout == 0">
                                                        <div class="group_item">
                                                            <label class="input_label">Details</label>
                                                            <div class="field_wpr">
                                                                <Field type="text" v-model="setting.rest_desc" :name="`plan_day_information_${s}_${w}`" @input="handleUpdateInputWeekDaySetting(setting)" />
                                                            </div>
                                                            <ErrorMessage :name="`plan_day_information_${s}_${w}`" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="action_wpr">
                                                        <button type="button" class="cancel_btn mr-1" @click="resetWeekplans()">Cancel</button>
                                                        <button type="button" class="okay_btn" @click="resetWeekplans()">Okay</button>
                                                    </div>
                                                </div> -->
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </template>
                </draggable>
            </Form>
        </div>
    </div>

    <plan-week-day-workout v-model="planWeekDayWorkout" :selected-week-day="selectedWeekDay" />
    <add-smart-week v-model="addSmartWeek" :workout-plan-id="workoutPlan.id" />
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { defineAsyncComponent } from 'vue'

    const AddSmartWeek = defineAsyncComponent(() => import('@/pages/workout/components/plan/AddSmartWeek'))
    const PlanWeekDayWorkout = defineAsyncComponent(() => import('@/pages/workout/components/plan/PlanWeekDayWorkout'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    import Draggable from 'vuedraggable'

    export default {
        name: 'Plan Week Setting',

        data () {
            return {
                newWeek: false,
                planWeeks: [],
                selectedDayIndex: 0,
                selectedWeekIndex: 0,
                selectedPlanWeek: {},
                weekSettingDropdown: false,
                selectedPlanWeekDay: {},
                weekDaySettingDropdown: false,
                editWeekName: false,
                planWeekDayWorkout: false,
                selectedWeekDay: {},
                selectedDayWorkoutIndex: 0,
                dayWorkoutDropdown: false,
                copyWeekWorkout: false,
                weekDays: [
                    { title: 'Monday', day: 1, isWorkout: false },
                    { title: 'tuesday', day: 2, isWorkout: false },
                    { title: 'Wednesday', day: 3, isWorkout: false },
                    { title: 'Thusday', day: 4, isWorkout: false },
                    { title: 'Friday', day: 5, isWorkout: false },
                    { title: 'Saturday', day: 6, isWorkout: false },
                    { title: 'Sunday', day: 7, isWorkout: false },
                ],
                addSmartWeek: false,
                planWeekLoader: false,
                workoutCopyInWeekLoader: false,
                hasError: false,
            }
        },

        props: {
            planStep: Number,
            workoutPlan: Object,
            hasPreview: Boolean,
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            Draggable,
            PlanWeekDayWorkout,
            AddSmartWeek,
        },

        watch: {
            workoutPlanWeeks (weeks) {
                const vm = this;

                vm.planWeeks = weeks ? JSON.parse(JSON.stringify(weeks)) : [];
            },

            copyWeekWorkout (val) {
                const vm = this;

                if (!val && !vm.workoutPlanWeekLoader) {
                    vm.planWeeks = vm.workoutPlanWeeks ? JSON.parse(JSON.stringify(vm.workoutPlanWeeks)) : [];
                }
            },
        },

        mounted () {
            const vm = this;

            setTimeout (() => {
                vm.planWeekLoader = true;

                vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id}).then((result) => {
                    vm.planWeekLoader = false;
                });

                const el = document.getElementById('workout-plan-wizard');

                if (el) {
                    el.onclick = (event) => {
                        setTimeout(() => {
                            let weekSettingDropdownClick = false;

                            vm.planWeeks.forEach((week) => {
                                const target = document.getElementById(`week-setting-wpr-${week.id}`);

                                if (target && target.contains(event.target)) {
                                    weekSettingDropdownClick = true;
                                }
                            })

                            if (vm.weekSettingDropdown && !weekSettingDropdownClick) {
                                vm.weekSettingDropdown = false;
                            }

                            if (vm.weekDaySettingDropdown) {
                                vm.weekDaySettingDropdown = false;
                            }
                        }, 50);
                    };
                }
            }, 100);
        },

        computed: mapState({
            workoutPlanUpdateLoader: state => state.workoutModule.workoutPlanUpdateLoader,
            workoutPlanWeeks: state => state.workoutModule.workoutPlanWeeks,
            workoutPlanWeekLoader: state => state.workoutModule.workoutPlanWeekLoader,
            smartWeekLoader: state => state.workoutModule.workoutPlanSmartWeekLoader,
            planWeekDuplicateLoader: state => state.workoutModule.planWeekDuplicateLoader,
            createPlanWeekLoader: state => state.workoutModule.createPlanWeekLoader,
        }),

        methods: {
            ...mapActions({
                getWorkoutPlanWeeks: 'workoutModule/getWorkoutPlanWeeks',
                saveWorkoutPlan: 'workoutModule/saveWorkoutPlan',
                deleteWorkoutPlan: 'workoutModule/deleteWorkoutPlan',
                createPlanBlankWeek: 'workoutModule/createPlanBlankWeek',
                updateWorkoutPlanWeek: 'workoutModule/updateWorkoutPlanWeek',
                createSmartWeek: 'workoutModule/createSmartWeek',
                storePlanWeekWorkouts: 'workoutModule/storePlanWeekWorkouts',
                deletePlanWeek: 'workoutModule/deletePlanWeek',
                duplicatePlanWeek: 'workoutModule/duplicatePlanWeek',
                sortWeekWorkouts: 'workoutModule/sortWeekWorkouts',
                sortPlanWeeks: 'workoutModule/sortPlanWeeks',
            }),

            createNewWeek (type) {
                const vm = this;

                if (type == 'blank') {

                    vm.createPlanBlankWeek(vm.workoutPlan.id).then((week) => {
                        if (week) {
                            vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id});

                            vm.newWeek = false;
                        }
                    })
                } else {
                    vm.addSmartWeek = true;
                    vm.newWeek      = false;
                }
            },

            closeWeekTypes () {
                const vm = this;

                vm.newWeek = false;
            },

            handleWorkoutWeekSetting (week) {
                const vm = this;

                const weekForm = {
                                    id: week.id,
                                    workout_plan_id: vm.workoutPlan.id,
                                    is_week_start_sunday: week.is_week_start_sunday,
                                    is_smart_week: week.is_smart_week,
                                };

                vm.updateWorkoutPlanWeek(weekForm).then((result) => {
                    if (result) {
                        vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id});
                    }
                });
            },

            handleUpdateSmartWeek (week) {
                const vm = this;

                const planWeekForm = vm.$refs['workout-plan-week-form'];

                if (planWeekForm) {
                    planWeekForm.validate().then((result) => {
                        if (result.valid && week.is_smart_week) {
                            const message = week.smart_week_id ? 'This workout week is also a smart workout week, do you want to create another smart workout week?' : 'You want make this workout week as smart workout week.';
                            const options = Helper.swalConfirmOptions('Are you sure?', message, 'Yes', false);

                            Swal.fire(options).then(({ isConfirmed }) => {
                                if (isConfirmed) {
                                    const parmas = { id: week.id, workout_plan_id: vm.workoutPlan.id, smart_week_name: week.smart_week_name };

                                    vm.createSmartWeek(parmas).then((result) => {
                                        if (result) {
                                            vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id});
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            },

            toggleWeekSetting (week) {
                const vm = this;

                const isDropdown = vm.weekSettingDropdown;

                setTimeout(() => {
                    vm.weekDaySettingDropdown = false;
                    vm.dayWorkoutDropdown     = false;
                    vm.copyWeekWorkout        = false;

                    if (vm.selectedPlanWeek.id != week.id) {
                        vm.weekSettingDropdown = true;
                        vm.selectedPlanWeek    = week;
                    } else {
                        vm.weekSettingDropdown = !isDropdown;
                    }
                }, 100);
            },

            toggleWeekDaySetting (weekIndex, dayIndex) {
                const vm = this;

                vm.dayWorkoutDropdown = false;
                vm.copyWeekWorkout    = false;

                const isDropdown      = vm.weekDaySettingDropdown;

                setTimeout(() => {
                    if (vm.selectedWeekIndex == weekIndex && vm.selectedDayIndex == dayIndex) {
                        vm.weekDaySettingDropdown = !isDropdown;
                    } else {
                        vm.selectedWeekIndex      = weekIndex;
                        vm.selectedDayIndex       = dayIndex;
                        vm.weekDaySettingDropdown = true;
                    }
                }, 100);

            },

            handleRenameWorkoutPlan (week) {
                const vm =  this;

                const weekForm = { id: week.id, workout_plan_id: vm.workoutPlan.id, name: week.name };

                vm.updateWorkoutPlanWeek(weekForm).then((result) => {
                    if (result) {
                        vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id});
                        vm.editWeekName = false;
                    }
                });
            },

            openPlanWeekDaySetting (daySetting, isUpdate = true, toggle = 1) {
                const vm = this;

                if (toggle) {
                    vm.planWeekDayWorkout  = true;
                    vm.selectedWeekDay     = daySetting;
                } else {
                    vm.planWeekDayWorkout  = false;
                }

                if (isUpdate) {
                    vm.handleUpdateWeekDay(daySetting);
                }
            },

            handleUpdateWeekDay (daySetting) {
                const vm       = this;
                const weekDay  = vm.getWeekDaySettingName(daySetting.day);
                const weekForm = {
                                    id: daySetting.workout_plan_week_id,
                                    workout_plan_id: vm.workoutPlan.id,
                                    week_settings: {[weekDay]: { desc: daySetting.desc, workout_instruction: daySetting.workout_instruction, rest_desc: daySetting.rest_desc, rest_name: daySetting.rest_name, has_workout: daySetting.has_workout }},
                                 };

                vm.updateWorkoutPlanWeek(weekForm).then((result) => {
                    if (result) {
                        vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id});
                    }
                });
            },

            getWeekDaySettingName(day) {
                if (day == 1) {
                    return 'mon_settings';
                } else if (day == 2) {
                    return 'tue_settings';
                } else if (day == 3) {
                    return 'wed_settings';
                } else if (day == 4) {
                    return 'thu_settings';
                } else if (day == 5) {
                    return 'fri_settings';
                } else if (day == 6) {
                    return 'sat_settings';
                } else if (day == 7) {
                    return 'sun_settings';
                }

                return false;
            },

            handleUpdateInputWeekDaySetting (daySetting) {
                const vm       = this;
                const weekDay  = vm.getWeekDaySettingName(daySetting.day);
                const weekForm = {
                                    id: daySetting.workout_plan_week_id,
                                    workout_plan_id: vm.workoutPlan.id,
                                    week_settings: {[weekDay]: { desc: daySetting.desc, workout_instruction: daySetting.workout_instruction, rest_desc: daySetting.rest_desc, rest_name: daySetting.rest_name, has_workout: daySetting.has_workout }},
                                    is_notification: false,
                                 };

                vm.updateWorkoutPlanWeek(weekForm);
            },

            toggleWeekDayWorkoutSetting (weekIndex, dayIndex, workoutIndex) {
                const vm = this;

                vm.copyWeekWorkout        = false;
                vm.weekSettingDropdown    = false;
                vm.weekDaySettingDropdown = false;

                const isDropdown          = vm.dayWorkoutDropdown;

                setTimeout(() => {
                    if (vm.selectedWeekIndex == weekIndex && vm.selectedDayIndex == dayIndex && vm.selectedDayWorkoutIndex == workoutIndex) {
                        vm.dayWorkoutDropdown = !isDropdown;
                    } else {
                        vm.selectedWeekIndex       = weekIndex;
                        vm.selectedDayIndex        = dayIndex;
                        vm.selectedDayWorkoutIndex = workoutIndex;
                        vm.dayWorkoutDropdown      = true;
                    }
                }, 100);
            },

            toggleCopyDayWorkout (week, workout) {
                const vm = this;

                const isDropdown = vm.copyWeekWorkout;

                setTimeout(() => {
                    vm.copyWeekWorkout = !isDropdown;

                    if (vm.copyWeekWorkout) {
                        vm.dayWorkoutDropdown = true;
                    }

                    vm.weekDays = [];

                    if (vm.copyWeekWorkout) {
                        let weekDays = [];

                        week.week_settings.forEach((day, index) => {
                            if (day && day.has_workout && day.workouts && day.workouts.length && day.workouts.some(({workout_id}) => workout_id == workout.workout_id)) {
                                weekDays.push({ title: day.title, day: day.day, isWorkout: true })
                            } else {
                                weekDays.push({ title: day.title, day: day.day, isWorkout: false })
                            }
                        });

                        vm.weekDays = weekDays;
                    }
                }, 100);

            },

            handleCopyToWeek (workout, weekIndex, weekDay) {
                const vm    = this;
                let week    = vm.planWeeks[weekIndex];
                const index = week.week_settings.findIndex(({title}) => title == weekDay.title);
                let day     = week.week_settings[index];

                if (!weekDay['isWorkout']) {
                    if (day) {
                        const workoutData = JSON.parse(JSON.stringify(workout));
                        workoutData.day   = weekDay.day;

                        if (day && day.workouts && day.workouts.length) {
                            if (day.workouts.length < 3) {
                                vm.planWeeks[weekIndex]['week_settings'][index]['workouts'].push(workoutData);
                                weekDay['isWorkout'] = true;
                            } else {
                                Swal.fire(Helper.swalWarningOptions('Oops!', 'Your maximum workout allowance limit reached, You can add only 3 workouts in a day.'));
                            }
                        } else {
                            vm.planWeeks[weekIndex]['week_settings'][index]['workouts']  = [workoutData];
                            weekDay['isWorkout'] = true;
                        }
                    }
                } else {
                    vm.planWeeks[weekIndex]['week_settings'][index]['workouts'] = vm.planWeeks[weekIndex]['week_settings'][index]['workouts'].filter(({workout_id}) => workout_id != workout.workout_id);
                    weekDay['isWorkout'] = false;
                }
            },

            handleStoreWeekWorkouts (workout, week) {
                const vm = this;

                const selectedWorkouts = [];

                week['week_settings'].forEach((weekDay) => {
                    if (weekDay.workouts && weekDay.workouts.length) {
                        const workoutData = weekDay.workouts.find(({workout_id}) => workout_id == workout.workout_id);

                        if (workoutData) {
                            weekDay.has_workout = 1;
                            vm.openPlanWeekDaySetting(weekDay, true, 0);

                            selectedWorkouts.push({id: workoutData.id, period: workoutData.period, day: workoutData.day})
                        }
                    }
                });

                const form = {
                                workout_plan_week_id: week.id,
                                workout_plan_id: week.workout_plan_id,
                                workouts: selectedWorkouts,
                                workout_id: workout.workout_id,
                            };

                vm.workoutCopyInWeekLoader = true;

                vm.storePlanWeekWorkouts(form).then((result) => {
                    if (result) {
                        vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id});

                        vm.copyWeekWorkout    = false;
                        vm.dayWorkoutDropdown = false;
                    }

                    vm.workoutCopyInWeekLoader = false;
                });
            },

            resetWeekplans () {
                const vm = this;

                vm.copyWeekWorkout        = false; //reset weekPlans by watcher
                vm.dayWorkoutDropdown     = false;
                vm.weekSettingDropdown    = false;
                vm.weekDaySettingDropdown = false;
            },


            handleDeletePlanWeek (id) {
                const vm    = this;
                const week  = vm.planWeeks.find((week) => week.id == id);

                if (week && week.week_settings.filter(setting => setting.workouts.length >= 1).length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this workout plan week`, 'Delete');

                    options.preConfirm = function () {
                                            const params = { id, workout_plan_id: vm.workoutPlan.id };

                                            return vm.deletePlanWeek(params).then((result) => {
                                                if (result) {
                                                    vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id});
                                                }
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    const params = { id, workout_plan_id: vm.workoutPlan.id };

                    vm.deletePlanWeek(params).then((result) => {
                        if (result) {
                            vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id});
                        }
                    });
                }
            },

            handleDuplicatePlanWeek (id) {
                const vm = this;

                vm.duplicatePlanWeek({ id,  workout_plan_id: vm.workoutPlan.id }).then((result) => {
                    if (result) {
                        vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id});
                    }
                });
            },

            handleSortWeekWorkouts (workouts, weekId) {
                const vm  = this;
                const ids = ([...workouts]).map(workout => workout.id);

                vm.sortWeekWorkouts({ ids, workout_plan_id: vm.workoutPlan.id, workout_plan_week_id: weekId }).then((result) => {
                    if (result) {
                        vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id});
                    }
                });
            },

            handleSortPlanWeeks () {
                const vm  = this;
                const ids = vm.planWeeks.map(week => week.id);

                vm.sortPlanWeeks({ ids, workout_plan_id: vm.workoutPlan.id }).then((result) => {
                    if (result) {
                        vm.getWorkoutPlanWeeks({workout_plan_id: vm.workoutPlan.id});
                    }
                });
            },

            handleValidate () {
                const vm = this;
                let result = true;

                vm.hasError = false;

                vm.planWeeks.forEach((week, w) => {
                    week.week_settings.forEach((setting, s) => {
                        if (setting.has_workout && setting.workouts.length == 0) {
                            result = false;
                            vm.hasError = true;
                        }
                    });
                });

                return result;
            },
        }
    }
</script>

<style scoped>
    .plan-wizard-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .progress_item {
        border-radius: 10px;
        max-width: 400px;
        margin: 0 auto;
        border: 1px solid #e0e0e0;
    }

    .progress_item .item_img img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
    }

    .progress_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .progress_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .progress_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .progress_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .step_name {
        display: flex;
        align-items: center;
    }

    .step_name h3 {
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #121525;
        padding: 5px 0;
        transition: all 0.3s ease-in-out;
    }

    .step_wpr h3 {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #32373b;
        padding: 5px 0;
    }
    .step_wpr h3.sub_header{
        font-size: 17px;
        line-height: 23px;
        font-weight: 500;
        color: #121525;
    }
    .section_content .section_header .handle-cat-drag{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        cursor: move;
    }

    .sec_header {
        font-size: 17px;
        line-height: 25px;
        font-weight: 500;
        color: #32373b;
        padding: 5px 0;
    }

    .track_list {
        padding: 15px;
    }

    .track_list li {
        padding: 0 20px 20px 40px;
        position: relative;
    }

    .track_list li:not(:last-child):after {
        position: absolute;
        content: '';
        left: 14px;
        top: 0;
        bottom: 0;
        border-left: 2px dashed #b0d1ff;
    }

    .step_number {
        position: absolute;
        left: 4px;
        top: 0;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2f7eed;
        border: 3px solid #b0d1ff;
        color: #fff;
        z-index: 1;
        font-size: 9px;
        transition: all 0.3s ease-in-out;
    }

    .track_list li .step_name.active h3 {
        font-size: 15px;
        line-height: 20px;
    }

    .track_list li .step_name.active .step_number {
        font-size: 13px;
        left: 0;
        width: 30px;
        height: 30px;
    }

    .time_listing {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -7px;
    }

    .time_listing li {
        padding: 5px 7px;
        width: 20%;
    }

    .time_listing li .checkbox {
        display: block;
    }

    .selection_box {
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        border: 1px solid #d9d9d9;
        color: #121525;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .time_listing li .checkbox input[type=radio]:checked~.selection_box {
        border: 1px solid #b0d1ff;
        background: rgba(47, 126, 237, 0.05);
        color: #2f7eed;
    }

    .selection_box:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }

    .day_type {
        display: flex;
        flex-direction: column;
        margin: 0 -15px;
    }

    .day_type li {
        padding: 10px 15px;
        flex: 1 0 50%;
    }

    .day_type li .day_wpr {
        display: flex;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
    }

    .day_type li .day_wpr label {
        min-width: 50px;
        height: auto;
        background: #fff;
        border-right: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px 0 0 5px;
        cursor: pointer;
    }

    .day_type li .day_wpr label span {
        width: 20px;
        height: 20px;
        border: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        border-radius: 3px;
        background: rgba(255, 255, 255, 0.3);
    }

    .day_type li .day_wpr label span i {
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .day_type li .day_wpr label input[type=radio]:checked~span i {
        transform: scale(1);
    }

    .day_type li .day_wpr h4 {
        font-size: 13px;
        line-height: 30px;
        padding: 7px 15px;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .sqr_input {
        width: 30px;
        height: 22px;
        border: 1px solid #d9d9d9;
        margin: 0 5px;
        text-align: center;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-size: 12px;
        font-weight: 500;
    }

    .date_input {
        width: 140px;
        height: 25px;
        border: 1px solid #d9d9d9;
        margin: 0 5px;
        padding: 0 5px;
        text-align: left;
        border-radius: 3px;
        background: #f5f5f5;
        color: #121525;
        font-size: 13px;
        font-weight: 500;
    }

    .multiselect.small_box {
        width: 80px;
        min-height: 25px;
        margin: 0 7px;
        font-size: 12px;
    }

    .sqr_input::-webkit-outer-spin-button,
    .sqr_input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .sqr_input[type=number] {
        -moz-appearance: textfield;
    }

    .time_selection {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    .time_selection .form_grp {
        padding: 10px;
        width: 50%;
        margin: 0;
        align-items: flex-end;
    }

    .day_sorting {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -5px;
    }

    .day_sorting li {
        padding: 5px;
        width: 25%;
        min-width: 90px;
    }

    .day_sorting li label {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 15px;
    }

    .circumference_list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px 15px -10px;
    }

    .circumference_list li {
        padding: 10px;
        width: 50%;
    }

    .circumference_list li label {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        padding: 15px;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .circumference_list li label:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .custom_list li {
        border: 1px solid #eaeaea;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 20px;
        margin-bottom: 30px;
    }

    .custom_list li h5.category_bar {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-weight: 500;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        padding: 0 15px;
        column-gap: 15px;
    }

    .category_bar .capsule_btn {
        border-right: 1px solid #d9d9d9;
    }

    .category_bar input {
        width: 100%;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        margin-right: 12px;
        padding: 15px 0;
    }

    .category_bar.active input,
    .category_bar input:focus {
        background: #f7faff;
        border-left: 1px solid #e9e9e9;
        border-right: 1px solid #e9e9e9;
        padding: 15px;
    }

    .category_bar .add_btn {
        font-size: 11px;
        line-height: 16px;
        margin: 0 0 0 15px;
        padding-right: 15px;
        border-right: 1px solid #d9d9d9;
    }

    .category_bar .add_btn i {
        font-size: 10px !important;
    }

    .category_bar .delete_btn {
        color: #eb1414;
        font-size: 11px;
        line-height: 16px;
        margin: 0 0 0 15px;
        cursor: pointer;
    }

    .category_bar .bar_text {
        display: flex;
        align-items: center;
        flex-grow: 1;
        position: relative;
    }

    .category_bar .bar_text:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border-bottom: 1px solid #e9e9e9;
    }

    .category_bar .bar_actions {
        display: flex;
        align-items: center;
        height: 50px;
    }

    .custom_list li h5 span.save {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .custom_list li h5 label {
        padding: 0 15px 0 0;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: auto;
        border-right: 1px solid #d9d9d9;
    }

    .custom_list li h5 label.has {
        color: #2f7eed;
    }

    .custom_list li h5 .edit_btn {
        font-size: 16px;
        color: #2F7FED;
        margin: 5px 0px;
        background: transparent;
        padding: 0px;
    }

    .custom_list li .section_wpr {
        border-top: 1px solid #eaeaea;
        margin: 10px -15px 0 -15px;
        padding: 10px 30px;
    }

    .sub_categories {
        padding: 30px 20px;
        border: 1px solid #eaeaea;
        background: #fafafa;
        border-radius: 6px;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .sub_categories h6 {
        font-size: 13px;
        line-height: 18px;
        color: #999;
        font-weight: 400;
        text-align: center;
    }

    .category_bar .quest_title {
        flex: 1 1 auto;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        margin-right: 12px;
        padding: 15px 0;
    }

    .prog_edit {
        background: #fff;
        padding: 20px 30px 30px 30px;
        border: 1px solid #eaeaea;
        border-radius: 6px;
        margin-top: 10px;
        cursor: auto;
    }

    .prog_edit :deep(.tab_content) {
        height: auto;
    }

    .prog_edit .field_wpr textarea {
        padding: 10px 15px;
        font-size: 13px;
        line-height: 18px;
        height: 100px;
        background: transparent;
        resize: none;
    }

    .grp_wpr {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        padding: 20px 0 5px 0;
    }

    .grp_wpr label .box_item {
        font-size: 13px;
        line-height: 15px;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }

    .grp_wpr label .box_item i {
        font-size: 15px;
    }

    .grp_wpr label.yes .box_item {
        border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e;
    }

    .grp_wpr label.no .box_item {
        border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414;
    }

    .grp_wpr label input[type="radio"]:checked~.box_item {
        opacity: 1;
    }

    .question-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 25px 0 5px;
        gap: 20px;
    }

    .question-options label {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }

    .question-options label p {
        font-size: 13px;
        line-height: 18px;
        padding-left: 12px;
    }

    .question-options .radio_box {
        width: 13px;
        height: 13px;
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        position: relative;
        display: block;
        margin-top: 3px;
    }

    .question-options .radio_box .dot {
        position: absolute;
        background: #5a5a5a;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .question-options label input[type="radio"]:checked~.radio_box .dot {
        transform: scale(1);
    }


    .modal.add_question .modal_header {
        padding: 0 45px;
    }

    .modal.add_question .modal_body {
        padding: 30px 45px 35px 45px;
        display: block;
    }

    .mcq_list li {
        border: 1px solid #d9d9d9;
        padding: 0 15px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .mcq_list li input {
        padding: 12px 15px 10px 0;
        font-size: 14px;
        line-height: 19px;
    }

    .mcq_list li button {
        border-left: 1px solid #d9d9d9;
        padding: 0 0 0 15px;
    }

    .col-2 {
        display: flex;
        margin: 0 -15px;
    }

    .col-2 .color_palette {
        flex: 1 0 50%;
        padding: 10px 15px;
    }

    .preview_content {
        width: 40%;
        position: relative;
        margin-right: -40%;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 2;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }

    .tab_sec li {
        display: flex;
        align-items: center;
    }

    .tab_sec .tag {
        padding: 2px 5px;
        border-radius: 7px;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 5px;
    }

    .cover_type {
        margin: 15px -35px 15px -35px;
        background: #fbfbfb;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 35px;
    }

    .cover_type .type_list {
        justify-content: space-around;
        margin-top: 15px;
    }

    .days {
        display: flex;
        padding: 15px 0;
        gap: 7px;
    }

    .cell .days {
        border-top: 1px solid #E6E6E6;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0 20px;
    }

    .days li {
        padding: 4px 0;
        flex: 0 1 55px;
    }

    .days li label {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 10px 6px 12px 6px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
    }

    .days li label span {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .days li label .tick_box {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #5a5a5a;
        position: relative;
        margin-top: 10px;
    }

    .days li label .tick_box:after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #5a5a5a;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .days li label input {
        margin-right: 7px;
        opacity: 0;
    }

    .days li label.active {
        background: rgba(47, 126, 237, 0.05);
        border: 1px solid #b0d1ff;
    }

    .days li label.active .tick_box:after {
        transform: scale(1);
    }

    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }

    .cell {
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        margin: 30px auto;
    }

    .cell:after,
    .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .cell .content_area {
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .cell .msg_preview {
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
    }

    .cell .sms_preview {
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }

    .cell .msg_preview:after,
    .cell .sms_preview::after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }

    .cell .sms_preview:after {
        right: 30px;
        left: auto;
    }

    .cell .msg_preview h2 {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }

    .cell .msg_preview p,
    .cell .sms_preview p {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 10px;
        font-weight: 400;
        color: #5a5a5a;
    }

    .cell .msg_preview p span {
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    .hourly_info {
        padding: 15px;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        background: #fff;
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #757575;
        text-align: center;
    }

    .form_grp :deep(.multiselect-single-label span) {
        font-size: 13px;
    }

    :deep(.cell .dashboard_footer) {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }

    :deep(.cell .dashboard_footer ul li) {
        padding: 2px;
    }

    :deep(.cell .dashboard_footer ul li a) {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }

    :deep(.cell .dashboard_footer h4) {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }

    :deep(.cell .dashboard_footer p) {
        font-size: 9px;
        line-height: 11px;
    }

    .info_bar {
        display: flex;
        flex-direction: column;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        margin: 20px;
    }

    .time {
        min-width: 150px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 10px 25px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }

    .time span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: block;
    }

    .step_bar2 {
        position: relative;
        padding: 20px 30px 10px 30px;
    }

    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }

    .reset-default {
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 15px;
        color: #747474;
        font-weight: 400;
        cursor: pointer;
        position: relative;
    }

    .cover-header-with-text {
        display: flex;
    }

    .cover-header-with-text .small-cover-info {
        margin: 0;
        margin-left: 10px;
    }

    .cover-header-with-text div>small {
        font-size: 12px;
    }

    .flex-center {
        display: flex;
        align-items: center;
    }


    /* toggle_section */
    .toggle_section {
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }

    .toggle_section i {
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    .global_setting .tabs_content .section_content {
        position: relative;
    }

    .global_setting .tabs_content .section_content .section_header {
        margin: 10px 0;
    }

    .global_setting .tabs_content .section_content:after {
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right: 20px;
        border-bottom: 1px solid #e9e9e9;
    }

    .global_setting .tabs_content .section_content.show:after,
    .global_setting .tabs_content .section_content:last-of-type:after,
    .global_setting .tabs_content .section_content.open_area:after {
        border: 0;
    }

    .global_setting .tabs_content .section_content .toggle_content {
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .global_setting .tabs_content .section_content.show .toggle_content {
        max-height: 1000vh;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .global_setting .tabs_content .section_content.show .toggle_section i {
        transform: rotate(-180deg);
    }

    /* --- */

    .section_content .section_header .status {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }

    .section_content .section_header .switch_option {
        flex: 1 1 auto;
    }

    .section_header h2 span {
        font-size: 13px;
        line-height: 17px;
        color: #f2a31d;
        display: block;
        padding-top: 7px;
    }

    .section_header h2 a {
        font-size: 13px;
        line-height: 17px;
        color: #121525;
        display: block;
        padding: 7px 7px 0 7px;
        position: relative;
    }

    .section_header h2 a .quick_info {
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        max-width: 200px;
        min-width: 100px;
        text-align: center;
        position: absolute;
        left: 0;
        top: auto;
        bottom: 100%;
        display: none;
        margin-bottom: 10px;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
    }

    .section_header h2 a .quick_info:after {
        content: "";
        position: absolute;
        bottom: -6px;
        top: auto;
        left: 10px;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }

    .section_header h2 a:hover .quick_info {
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    .btn_list {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
    }

    .btn_list li {
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        padding: 5px 10px;
        cursor: pointer;
    }

    @media(max-width: 1499px) {
        .time_selection .form_grp {
            flex: 0 0 100%;
            width: 100%;
        }

        .selection_box {
            font-size: 11px;
            line-height: 15px;
        }

        .time_listing li {
            padding: 3px;
        }

        .tab_sec li {
            font-size: 12px;
            line-height: 15px;
            padding: 8px 12px;
        }
    }

    :deep(.weekdays .multiselect-single-label-text) {
        text-transform: capitalize;
    }

    :deep(.question_list) {
        display: block;
        margin: 0;
    }

    :deep(.question_list li) {
        width: 100%;
    }

    :deep(.question_list li h5 label) {
        margin-right: auto;
        margin-left: 0;
        padding-left: 0;
    }

    .grp_wpr.grp_option {
        display: flex;
        flex-wrap: wrap;
    }

    /* .box_item {
        flex: 1 0 calc(50% - 10px);
    } */

    /* .box_item label {
        border: 1px solid #CBCDD3;
        border-radius: 6px;
        padding: 5px 7px;
        display: flex;
        align-items: center;
    } */

    .mrl-5 {
        margin: 0 -5px;
    }

    .p-10-15 {
        padding: 10px 15px !important;
    }

    :deep(.dp__input_wrap input) {
        font-size: 12px;
        color: #5a5a5a;
    }

    .tab_slider {
        margin: 0 -15px;
    }

    :deep(.dp__main .dp__input) {
        font-size: 11px;
        max-width: 120px;
        padding: 0 0 0 35px !important;
    }

    .addblock_section {
        position: relative;
        width: 100%;
        padding: 0 10px 10px 10px;
    }
    .addblock_section .add_btn{
        padding: 6px 15px;
        background: #fff;
        border-radius: 15px;
        font-size: 13px;
    }
    .addblock_section .add_btn i {
        font-size: 10px !important;
    }

    .addblock_section .add_types {
        max-width: 572px;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, .1);
        text-align: center;
        z-index: 2;
    }

    .addblock_section .add_types:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }

    .addblock_section .add_types ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        padding: 30px 20px;
    }

    .addblock_section .add_types ul li {
        flex: 0 1 80px;
    }

    .addblock_section .add_types ul li .card_item {
        padding: 15px 5px;
    }

    .addblock_section .add_types .title {
        padding: 15px 30px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        color: #121525;
    }

    .block_list .block_actions {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .block_list .block_actions button {
        font-size: 15px;
        color: #121525;
        cursor: pointer;
    }

    .block_list .block_actions button.up_down {
        color: #999;
    }

    .block_list .block_actions .block_setting {
        position: relative;
        cursor: pointer;
    }

    .block_list .block_actions .block_setting i {
        font-size: 15px;
        color: #999;
    }

    .block_list .block_actions .block_setting .dropdown_wpr {
        min-width: 250px;
        max-height: 350px;
        overflow-y: scroll;
        right: 1px;
        left: auto;
        padding: 15px;
        margin-bottom: 10px;
        z-index: 4;
    }

    .block_list .block_actions .block_setting .dropdown_wpr::-webkit-scrollbar {
        display: none;
    }

    .block_list .block_actions .block_setting .dropdown_wpr:before {
        display: none;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .sec_label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        padding: 3px 0;
        display: block;
    }

    .block_list .block_actions .block_setting .dropdown_wpr ul li {
        padding-top: 13px;
        border: 0;
        background: #f5f5f5;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        margin-bottom: 10px;
        border-radius: 5px;
    }

    .block_list .block_actions .block_setting .dropdown_wpr ul li .switch_option h5 {
        font-weight: 400;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .group_item .input_label {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr,
    .block_list .block_actions .block_setting .dropdown_wpr .form_grp .multiselect {
        background: #fff;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr input,
    .block_list .block_actions .block_setting .dropdown_wpr .form_grp .multiselect {
        height: 30px;
        min-height: 30px;
        font-size: 11px;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr.has_suffix {
        padding-right: 40px;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .field_wpr.has_suffix .suffix {
        width: 40px;
        font-size: 11px;
        line-height: 14px;
        background: #f5f5f5;
        border-radius: 0 5px 5px 0;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown) {
        min-width: 100%;
    }

    .block_list .block_actions .block_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown ul.multiselect-options li) {
        padding: 5px 10px;
        font-size: 11px;
        line-height: 15px;
    }

    .block_list li .block_panel {
        border: 1px solid #eaeaea;
        background: #f5f5f5;
        border-radius: 6px;
        padding: 30px;
        margin-bottom: 40px;
        transition: all 0.3s ease-in-out;
    }

    .block_list li .block_panel.active {
        border: 1px solid #c8dfff;
        background: #fbfbfb;
    }

    .block_panel li.add_item button {
        width: 150px;
        border-radius: 6px;
        background: #fff;
        border: 1px solid #f5f5f5;
        padding: 20px 10px;
        min-height: 134px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: flex-start;
        font-size: 13px;
        line-height: 18px;
        gap: 10px;
        color: #999;
        cursor: pointer;
    }

    .block_library {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 0;
    }

    .block_library ul.list {
        display: flex;
        flex-wrap: wrap;
        margin: 20px -10px;
        width: 100%;
    }

    .block_library ul.list.shake {
        animation: headShake 0.3s linear 0s infinite alternate;
    }

    .block_library ul.list>li {
        padding: 20px 10px;
        width: 33.333%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: relative;
    }

    .video_wpr {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 5px;
        overflow: hidden;
    }

    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }

    .block_library ul li h6 {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
    }

    .block_library ul li .action_items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 10px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .block_library ul li:hover .action_items,
    .block_library ul li.selected .action_items,
    .block_library ul.target li .action_items {
        opacity: 1;
    }

    .block_library ul li .action_items label.checkbox span {
        width: 12px;
        flex: 0 0 12px;
        height: 12px;
        border-radius: 2px;
    }

    .block_library ul li .action_items label.checkbox span i {
        font-size: 8px;
    }

    .block_library ul li .action_items a {
        cursor: pointer;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
    }

    .block_library ul li .action_items a i {
        font-size: 9px;
        padding-right: 4px;
    }

    .block_library .library_header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .block_library .library_header a {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
        cursor: pointer;
    }

    .block_library .filter_search {
        /* border: 1px solid #efefef; */
        height: 34px;
        border-radius: 20px;
        padding: 0 34px 0 0;
        position: relative;
        background: #fbfbfb;
        max-width: 400px;
        width: 100%;
    }

    .block_library .filter_search input {
        font-size: 11px;
        line-height: 34px;
        color: #666;
        border: 0;
        width: 100%;
        padding: 0 15px;
        background: transparent;
        box-sizing: border-box;
    }

    .block_library .filter_search .search_btn {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 0;
        font-size: 11px;
        color: #333;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }

    .block_library .filters {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 25px 0 10px 0;
        position: relative;
        z-index: 2;
    }

    .block_library .filters h5 {
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 400;
    }

    .filters .filter_item,
    .attribute_list {
        position: relative;
        font-size: 11px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        cursor: pointer;
    }

    .filters .filter_item li,
    .other_filter_items li {
        font-size: 11px;
        line-height: 15px;
    }

    .other_filter_items {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f1f1;
        border-radius: 50%;
        font-size: 10px;
        position: relative;
        cursor: pointer;
    }

    .attribute_bar {
        padding: 15px 0;
        background: #fff;
        border-left: 1px solid #e9e9e9;
        width: 230px;
        max-height: 100vh;
        overflow-y: overlay;
        position: relative;
        margin-right: -230px;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
    }

    .attribute_bar.show {
        margin-right: 0;
    }

    .attribute_bar::-webkit-scrollbar {
        width: 4px;
    }

    .attribute_bar::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #cacaca;
    }

    .attribute_bar h3 {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        padding: 0 20px 5px 20px;
        display: flex;
        align-items: center;
    }

    .attribute_bar h3 button {
        font-size: 13px;
        color: #999;
        margin-left: auto;
        cursor: pointer;
    }

    .toggle_btn {
        display: flex;
        justify-content: flex-end;
        padding: 6px 20px 12px 20px;
    }

    .toggle_btn a {
        padding: 4px 10px;
        background: #f5f5f5;
        font-size: 10px;
        line-height: 12px;
        color: #121525;
        font-weight: 500;
        display: block;
        border-radius: 10px;
        cursor: pointer;
    }

    .attribute_grp {
        border-top: 1px solid #f5f5f5;
    }

    .attribute_grp h4 {
        padding: 12px 15px;
        font-size: 13px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .attribute_grp h4 a {
        color: #5a5a5a;
        margin-right: 15px;
        font-size: 11px;
        display: flex;
        align-items: center;
    }

    .attribute_grp h4 a.pinned {
        color: #2f7eed;
    }

    .attribute_grp h4>i {
        margin-left: auto;
        font-size: 11px;
        color: #999;
        transition: all 0.3s ease-in-out;
    }

    .attribute_grp ul {
        padding: 0 15px;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .attribute_grp.active ul {
        max-height: 10000px;
    }

    .attribute_grp.active h4>i {
        transform: rotate(-180deg);
    }

    .attribute_bar ul li {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 10px 15px;
    }

    .attribute_bar ul li:last-child {
        margin-bottom: 15px;
    }

    .attribute_bar ul li label.checkbox,
    .dropdown_wpr ul li label.checkbox {
        justify-content: flex-start;
        gap: 10px;
        cursor: pointer;
    }

    .add_attribute {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        padding: 10px 20px;
        background: #2f7eed;
        color: #fff;
        border-radius: 5px;
        margin: auto 20px 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        gap: 7px;
        cursor: pointer;
    }

    .add_attribute i {
        font-size: 9px;
    }

    .filters .attribute_bar {
        position: fixed;
        right: -230px;
        top: 0;
        height: 100vh;
        overflow-y: scroll;
        transition: all 0.3s ease-in-out;
    }

    .filters .attribute_bar.show {
        right: 0;
    }

    .search_tags {
        background: #fbfbfb;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
        margin-top: 20px;
    }

    .search_tags li {
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: relative;
    }

    .search_tags .delete_btn {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .search_tags li:hover .delete_btn {
        opacity: 1;
    }

    .dropdown_wpr ul li label.checkbox span i {
        margin: 0;
    }

    .attribute_bar ul li label.checkbox span.active i,
    .dropdown_wpr ul li label.checkbox span.active i {
        transform: scale(1);
        opacity: 1;
    }

    .bulk_action {
        border-right: 1px solid #d9d9d9;
        padding-right: 20px;
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .bulk_action .delete_btn {
        color: #eb1414;
        cursor: pointer;
    }

    .filters .bulk_action .filter_item,
    .filters .bulk_action .filter_item i {
        color: #2f7eed;
    }

    .filter_item i {
        font-size: 9px;
        padding: 5px;
        vertical-align: top;
    }

    .filters .filter_item i,
    .attribute_list i {
        color: #7a7a7a;
        pointer-events: none;
    }

    .filters .filter_item .dropdown_wpr,
    .attribute_list .dropdown_wpr {
        min-width: 160px;
        overflow-y: hidden;
    }

    .filters .filter_item .dropdown_wpr ul,
    .attribute_list .dropdown_wpr ul {
        max-height: 400px;
        overflow-y: scroll;
    }

    .attribute_list .dropdown_wpr ul {
        max-height: 200px;
        overflow-y: scroll;
    }

    .filters .filter_item .dropdown_wpr ul::-webkit-scrollbar,
    .other_filter_items .dropdown_wpr::-webkit-scrollbar,
    .attribute_list .dropdown_wpr ul::-webkit-scrollbar,
    .attribute_list .dropdown_wpr ul::-webkit-scrollbar {
        display: none;
    }

    .filters .filter_item .dropdown_wpr ul li,
    .other_filter_items .dropdown_wpr ul li,
    .attribute_list .dropdown_wpr ul li {
        border: 0;
    }

    .filters .filter_item .dropdown_wpr ul li.active {
        background: #f5f5f5;
    }

    .dropdown_wpr ul li.dropdown-header {
        background: #e9e9e9;
    }

    .block_list li .block_panel ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    .block_list li .block_panel ul li {
        padding: 10px 15px;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        position: relative;
    }

    .block_list li .block_panel ul li .block_action {
        position: absolute;
        right: 15px;
        bottom: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        transition: all 0.3s ease-in-out;
        opacity: 0;
    }

    .block_list li.circuit .block_panel ul li .block_action {
        right: 30px;
    }

    .block_list li .block_panel ul li .block_action button {
        font-size: 11px;
        color: #eb1414;
        cursor: pointer;
    }

    .block_list li .block_panel ul li:hover .block_action {
        opacity: 1;
    }

    .block_list li .block_panel ul li h6 {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .block_list li .block_panel ul li h6 .v_tag {
        width: 14px;
        height: 14px;
        background: #999;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        line-height: 10px;
        font-weight: 500;
        color: #fff;
        margin-right: 5px;
        border-radius: 50%;
    }

    .block_list li .block_panel ul li.rest_list {
        width: auto;
    }

    .block_list li.circuit .block_panel ul li {
        padding-right: 30px;
    }

    .block_list li .block_panel ul li .circuit {
        width: 40px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        position: absolute;
        font-size: 15px;
        color: #2f7eed;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
        z-index: 1;
        gap: 5px;
        visibility: hidden;
    }

    .block_list li .block_panel ul li .circuit a {
        font-size: 10px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        width: 40px;
        text-align: center;
    }

    .block_list li.circuit .block_panel ul li .circuit {
        visibility: visible;
    }

    /* .block_list li:not(.circuit) .block_panel ul li:nth-child(3):before{
            width: 22px;
            height: 22px;
            background: #2f7eed;
            border-radius: 11px;
            content: "SS";
            position: absolute;
            font-size: 10px;
            font-weight: 500;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            letter-spacing: 0.5px;
            top: 50%;
            left: -11px;
            transform: translateY(-50%);
            z-index: 1;
        } */

    .rest_list {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rest_circle {
        width: 110px;
        height: 110px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #ffc4c4;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        cursor: pointer;
    }

    .rest_circle span {
        font-size: 15px;
        line-height: 20px;
    }

    .edit_rest {
        width: 230px;
        position: absolute;
        top: 100%;
        right: 1px;
        left: auto;
        margin-top: 10px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, .1);
        text-align: center;
        z-index: 4;
    }

    /* .edit_rest:after {
            position: absolute;
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            width: 20px;
            height: 13px;
            right: calc(100% - 4px);
            bottom: 50%;
            transform: rotate(90deg);
        } */
    .edit_rest .title {
        padding: 10px 20px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
    }

    .edit_rest .setting_wpr {
        padding: 20px;
    }

    .edit_rest .input_label {
        font-size: 11px;
        line-height: 13px;
    }

    .edit_rest .field_wpr input {
        height: 32px;
        font-size: 11px;
    }

    .edit_rest :deep(.color-picker .field_wpr input) {
        height: 30px;
        padding: 0 10px;
        font-size: 11px;
    }

    .edit_rest :deep(.color-picker .field_wpr.has_prefix) {
        padding-left: 30px;
    }

    .edit_rest :deep(.color-picker .field_wpr.has_prefix .prefix-right) {
        top: 3px;
        bottom: 3px;
        right: 3px;
    }

    .edit_rest :deep(.color-picker .field_wpr.has_prefix .prefix) {
        width: 30px;
        height: 30px;
    }

    .edit_rest :deep(.color-picker .pickr),
    .edit_rest :deep(.color-picker .pickr .pcr-button) {
        width: 20px;
        height: 20px;
        line-height: 0;
    }

    .edit_rest :deep(.color-picker .sub_header) {
        font-size: 11px;
        line-height: 14px;
    }

    .edit_rest :deep(.color-picker .swatches li) {
        padding: 4px;
    }

    .edit_rest :deep(.color-picker .swatches li span) {
        width: 20px;
        height: 20px;
    }

    .edit_rest :deep(.color-picker .swatches li span i) {
        font-size: 10px;
    }

    .edit_rest .action_wpr {
        padding: 12px 20px;
        border-top: 1px solid #f5f5f5;
        margin: 0;
    }

    .edit_rest .action_wpr .btn {
        min-width: auto;
        height: 30px;
        font-size: 10px;
        line-height: 28px;
        padding: 0 15px;
    }

    .edit_rest .action_wpr .btn.save_btn {
        color: #fff;
    }

    .workout_config .modal_container {
        padding: 0;
        max-width: 700px;
        width: 100%;
    }

    .workout_config .modal_header {
        padding: 20px 30px;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
    }

    .workout_config .modal_footer {
        padding: 20px 30px;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        border-radius: 0 0 10px 10px;
    }

    .workout_config .modal_header h2 {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        text-align: left;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .workout_config .modal_header button {
        color: #999;
        font-size: 15px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-self: center;
    }

    .workout_config .modal_body {
        width: 100%;
        padding: 20px 30px;
        max-height: 600px;
    }

    .workout_config .edit_content {
        display: flex;
        gap: 30px;
        margin-bottom: 20px;
    }

    .workout_config .edit_content .thumb_section {
        flex: 0 1 600px;
        display: flex;
        gap: 20px;
        background: #fbfbfb;
        border-radius: 5px;
    }

    .workout_config .edit_content .thumb_section .thumb_title {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin: 15px 0;
        display: flex;
    }

    .workout_config .edit_content .thumb_section .thumb_title .v_tag {
        width: 20px;
        height: 20px;
        background: #999;
        border: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 14px;
        font-weight: 600;
        color: #fff;
        margin-left: 10px;
        border-radius: 50%;
    }

    .workout_config .edit_content .thumb_section .exercise_info {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        margin-top: 10px;
    }

    .workout_config .edit_content .video_section {
        flex: 0 1 280px;
    }

    .workout_config .edit_content .setting_wpr {
        flex: 1 0 180px;
    }

    .set_wpr {
        display: flex;
        margin: 0 -7px;
    }

    .set_wpr .single_item {
        flex: 0 1 15%;
        padding: 7px;
    }

    .set_wpr .public_name {
        flex: 0 1 294px;
        padding: 0 7px;
    }

    .set_wpr .public_descriptions {
        max-width: 544px;
        width: 100%;
        padding: 0 7px;
    }

    .set_wpr .item_wpr {
        flex: 0 1 25%;
        padding: 7px;
    }

    .set_wpr .item_wpr:last-of-type {
        margin-left: auto;
    }

    .column-2 {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
    }

    .column-2 .item {
        flex: 1 0 calc(50% - 7px);
    }

    .set_wpr .input_label {
        font-size: 14px;
        line-height: 19px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .workout_config .fld_label {
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 5px;
        display: block;
    }

    .workout_config .field_wpr input,
    .workout_config .form_grp .multiselect {
        height: 40px;
        min-height: 40px;
        font-size: 13px;
    }

    .form_grp.set_wpr .multiselect {
        min-height: 40px;
    }

    .var_list {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        padding: 10px 0;
    }

    .var_list .blank_space {
        min-width: 21%;
    }

    .radio_opt {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }

    .radio_opt p {
        font-size: 14px;
        line-height: 19px;
        padding-left: 8px;
    }

    .radio_box {
        width: 13px;
        height: 13px;
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        position: relative;
        display: block;
        margin-top: 4px;
    }

    .radio_box .dot {
        position: absolute;
        background: #5a5a5a;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .radio_opt input[type="radio"]:checked~.radio_box .dot {
        transform: scale(1);
    }

    .var_table {
        padding: 20px 0;
    }

    .var_table table {
        width: 100%;
        background: #fbfbfb;
        border-radius: 6px;
        border-collapse: collapse;
    }

    .var_table table thead {
        border-bottom: 1px solid #efefef;
    }

    .var_table table tbody tr:not(:last-child) {
        border-bottom: 1px solid #f3f3f3;
    }

    .var_table table th {
        padding: 12px 18px;
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
    }

    .var_table table td {
        padding: 10px 18px 15px 18px;
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 300;
        vertical-align: bottom;
    }

    .var_table table td .row {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .var_table table td input {
        width: 50px;
        font-weight: 300;
        height: 30px;
        border: 1px solid #CBCDD3;
        border-radius: 5px;
        padding-left: 5px;
    }

    .other_content {
        width: 100%;
        padding: 20px 0 0 0;
    }

    .group_title {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .group_title h4 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        color: #121525;
        margin-bottom: 10px;
        margin-right: auto;
    }

    .group_title h4 span {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        padding-left: 15px;
    }

    .att_tags {
        background: #fbfbfb;
        border-radius: 6px;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }

    .att_tags li {
        background: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 7px 15px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        position: relative;
    }

    .att_tags .delete_btn {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: #eb1414;
        color: #fff;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .att_tags li:hover .delete_btn {
        opacity: 1;
    }

    .att_tags li.exercise,
    .search_tags li.exercise {
        border-color: #88d3d9;
    }

    .att_tags li.position,
    .search_tags li.position {
        border-color: #b99191;
    }

    .att_tags li.contraction,
    .search_tags li.contraction {
        border-color: #968bca;
    }

    .att_tags li.muscle,
    .search_tags li.muscle {
        border-color: #76afff;
    }

    .att_tags li.movement,
    .search_tags li.movement {
        border-color: #cb72ff;
    }

    .att_tags li.equipment,
    .search_tags li.equipment {
        border-color: #56e094;
    }

    .att_tags li.skeletal,
    .search_tags li.skeletal {
        border-color: #fb76c8;
    }

    .att_tags li.difficulty,
    .search_tags li.difficulty {
        border-color: #f2a31d;
    }

    .other_content .field_wpr {
        background: #fff;
    }

    .other_content textarea {
        font-size: 13px;
        line-height: 18px;
        resize: vertical;
    }

    .video-overlay {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        top: 0;
        cursor: pointer;
    }

    .section_container {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
        padding: 30px 50px;
    }

    .section_title {
        font-size: 22px;
        line-height: 30px;
        color: #121525;
        font-weight: 400;
        text-align: center;
        padding: 0 0 20px 0;
    }

    .content_panel {
        border-radius: 8px;
        background: #fff;
        position: relative;
        padding: 30px;
    }

    .content_panel.circuit .set_wpr .single_item.sets,
    .content_panel.circuit .set_wpr .item_wpr.rest,
    .content_panel.circuit .form_grp :deep(ul.multiselect-options li#multiselect-option-Superset),
    .content_panel.progression .form_grp :deep(ul.multiselect-options li[aria-label="Using Time"]),
    .content_panel.no_superset .form_grp :deep(ul.multiselect-options li#multiselect-option-Superset),
    .disable {
        pointer-events: none;
        opacity: 0.7;
    }

    .global_setting .section_header h2 {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .global_setting .section_header h2 input {
        font-size: 16px;
        line-height: 20px;
        background: transparent;
        width: 200px;
        background: #f0f5ff;
        padding: 10px 15px;
        border-radius: 5px;
    }

    .global_setting .section_header h2 .save_btn {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .form_grp.small {
        margin: 0;
    }

    .form_grp.small .field_wpr {
        width: 80px;
    }

    .form_grp.small :deep(.multiselect-multiple-label),
    .form_grp.small :deep(.multiselect-placeholder),
    .form_grp.small :deep(.multiselect-single-label) {
        padding: 0 20px 0 5px;
    }

    .form_grp.small :deep(.multiselect) {
        min-height: 30px;
        height: 30px;
    }

    .form_grp.small :deep(.multiselect-single-label span) {
        font-size: 11px;
    }

    .form_grp.small :deep(.multiselect-caret) {
        margin-right: 5px;
    }

    .load_more {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        line-height: 18px;
        color: #999;
        gap: 10px;
    }

    .smart_blocklist .add_btn {
        font-size: 13px;
        line-height: 16px;
    }

    .smart_blocklist .add_btn i {
        margin-right: 5px;
        font-size: 9px !important;
    }

    .smart_blocklist>ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .smart_blocklist>ul .block_info {
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        padding: 7px 15px 20px 15px;
    }

    .smart_blocklist>ul .block_info>li {
        padding: 7px;
        width: 33.333%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .smart_blocklist>ul .block_info li h6 {
        font-size: 9px;
        line-height: 11px;
        color: #121525;
        font-weight: 400;
        margin: 5px 0;
    }

    .section_header .handle-cat-drag input {
        font-size: 16px;
        line-height: 20px;
        background: transparent;
        width: 200px;
        background: #f0f5ff;
        padding: 10px 15px;
        border-radius: 5px;
    }

    .section_header .handle-cat-drag .save_btn {
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .plan-wizard-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .section_header.center {
        justify-content: center;
        margin-bottom: 20px;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }

    .preview_content {
        width: 40%;
        position: relative;
        margin-right: -40%;
        transition: all 0.3s ease-in-out;
        background: #fff;
        z-index: 2;
    }

    .preview_content.show {
        margin-right: 0;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
        font-size: 14px;
    }

    .week_setting {
        position: relative;
    }

    .addblock_section .add_types {
        max-width: 252px;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        box-shadow: 5px 50px 50px rgba(0, 0, 0, .1);
        text-align: center;
        z-index: 2;
    }

    .addblock_section .add_types:after {
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        bottom: 100%;
        transform: scale(-1);
    }

    .addblock_section .add_types ul {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 20px 20px;
    }

    .addblock_section .add_types ul li {
        flex: 0 1 100px;
    }

    .addblock_section .add_types ul li .card_item {
        padding: 15px 5px;
    }

    .addblock_section .add_types .title {
        padding: 15px 30px;
        text-align: center;
        border-bottom: 1px solid #f5f5f5;
        font-size: 13px;
        line-height: 17px;
        font-weight: 400;
        color: #121525;
    }

    .week_list .week_actions {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .week_list .week_actions button {
        font-size: 15px;
        color: #121525;
        cursor: pointer;
    }

    .week_list .week_actions button.up_down {
        color: #999;
    }

    .week_list .week_actions .week_setting {
        position: relative;
        cursor: pointer;
    }

    .week_list .week_actions .week_setting i {
        font-size: 15px;
        color: #999;
    }

    .week_list .week_actions .week_setting .dropdown_wpr {
        min-width: 200px;
        right: 1px;
        left: auto;
        padding: 15px;
        margin-bottom: 10px;
        z-index: 4;
        transform: none;
    }

    .week_list .week_actions .week_setting .dropdown_wpr:before {
        display: none;
    }

    .week_list .week_actions .week_setting .dropdown_wpr ul li {
        padding-top: 13px;
        border: 0;
        background: #f5f5f5;
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        margin-bottom: 10px;
        border-radius: 5px;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .group_item .input_label {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .field_wpr {
        background: #fff;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .field_wpr input {
        height: 30px;
        min-height: 30px;
        font-size: 11px;
        padding: 0 10px;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .field_wpr.has_suffix {
        padding-right: 40px;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .field_wpr.has_suffix .suffix {
        width: 40px;
        font-size: 11px;
        line-height: 14px;
        background: #f5f5f5;
        border-radius: 0 5px 5px 0;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown) {
        min-width: 100%;
    }

    .week_list .week_actions .week_setting .dropdown_wpr .form_grp :deep(.multiselect .multiselect-dropdown ul.multiselect-options li) {
        padding: 5px 10px;
        font-size: 11px;
        line-height: 15px;
    }

    .week_list li .week_panel {
        border: 1px solid #eaeaea;
        background: #fafafb;
        /* overflow: hidden; */
        border-radius: 6px;
        /* padding: 20px 30px 30px 30px; */
        margin-bottom: 40px;
        transition: all 0.3s ease-in-out;
    }

    .week_list li .week_panel ul {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -1px;
        margin-right: -1px;
    }

    .week_list li .week_panel>ul>li {
        width: 14.28%;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 175px;
        border-right: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9;
    }
    .week_list li .week_panel>ul.narrow>li {
        width: 25%;
    }

    .week_list li .week_panel ul li h5 {
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        width: 100%;
        height: 34px;
        padding: 0 10px;
        border-bottom: 1px solid #e9e9e9;
    }
    .week_list li .week_panel ul li h5 .day_title{
        width: 100%;
        display: flex;
        align-items: center;
    }
    .week_list li .week_panel ul li h5 .day_title .switch_option{
        transform: scale(0.9);
        margin-left: auto;
    }

    .week_list li .week_panel ul li h5 i {
        font-size: 9px;
        color: #999;
        margin-left: 10px;
    }

    .week_list li .week_panel ul li .box_item {
        flex: 1 0 120px;
        width: 100%;
        padding: 15px 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        background: #fff;
    }
    .week_list li .week_panel ul li .box_item .add_workout{
        padding: 4px 10px;
        border-radius: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        border: 1px solid #f5f5f5;
        background: #f7f7f7;
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #888;
        cursor: pointer;
    }
    .week_list li .week_panel ul li .box_item .add_workout i{
        font-size: 8px;
    }
    .week_list li .week_panel ul li .box_item p {
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 500;
        position: relative;
        cursor: pointer;
    }

    .week_list li .week_panel ul li .box_item ol {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .week_list li .week_panel ul li .box_item ol>li {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: top;
        gap: 5px;
        position: relative;
    }

    .week_list li .week_panel ul li .box_item ol>li>label {
        background: #f5f5f5;
        border-radius: 12px;
        display: flex;
        position: relative;
    }

    .week_list li .week_panel ul li .box_item ol>li>label span {
        padding: 4px 15px;
        font-size: 11px;
        line-height: 14px;
    }

    .week_list li .week_panel ul li .box_item ol>li>label a {
        background: #e5e5e5;
        color: #121525;
        width: 25px;
        flex-shrink: 0;
        font-size: 9px;
        line-height: 12px;
        border-radius: 0 12px 12px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .week_list li .week_panel ul li .box_item ol>li>label a i{
        padding: 0;
        font-size: 11px;
    }

    .week_list li .week_panel ul li .box_item ol li i {
        padding: 5px 3px;
        color: #5a5a5a;
        cursor: pointer;
    }

    .week_list .box_item ol li .dropdown_wpr.small {
        min-width: 60px;
        padding: 0;
        left: 100%;
        right: auto;
    }

    .week_list .box_item ol li .dropdown_wpr ul li {
        border: 0;
    }

    .week_list .dropdown_wpr {
        min-width: 150px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        padding: 15px;
        margin-bottom: 10px;
        z-index: 4;
    }
    .week_list .box_item .action_wpr{
        margin: 15px 0 0 0;
    }
    .week_list .box_item .action_wpr .okay_btn {
        padding: 5px 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #fff;
        background: #2f7eed;
        border-radius: 3px;
        cursor: pointer;
    }
    .week_list .box_item .action_wpr .cancel_btn{
        padding: 5px 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        background: transparent;
        border-radius: 3px;
        cursor: pointer;
        border: 1px solid #DBDDE1;
    }

    .week_list .dropdown_wpr:before {
        display: none;
    }

    .week_list .dropdown_wpr .group_item .input_label {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 6px;
        display: block;
    }

    .week_list .dropdown_wpr .group_item .field_wpr input {
        height: 30px;
        font-size: 11px;
        padding: 0 10px;
    }

    .workout_card li {
        display: flex;
        justify-content: flex-start;
        /* align-items: flex-start; */
        gap: 5px;
        margin-bottom: 20px;
    }

    .workout_card li .wk_item {
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 8px;
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        position: relative;
    }

    .workout_card li .period {
        flex: 0 0 55px;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 5px;
    }
    .workout_card li .period :deep(.multiselect-single-label) {
        padding: 0 15px 0 5px;
    }

    .workout_card li .period :deep(.multiselect-caret) {
        margin-right: 0;
    }

    .workout_card li .period :deep(.multiselect-single-label-text) {
        font-size: 11px;
    }

    .workout_card li .wk_item img {
        width: 100px;
        height: auto;
        object-fit: cover;
    }

    .workout_card .card_details {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #121525;
        padding: 10px 15px;
    }

    .workout_card .card_details p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 400;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .workout_card li span {
        font-size: 11px;
        line-height: 14px;
        color: #121525;
        position: absolute;
        right: 15px;
        top: 10px;
        cursor: pointer;
    }

    .workout_card li span i {
        font-size: 9px;
        margin-right: 5px;
    }

    .modal.workout_modal .modal_body {
        padding-top: 0;
    }

    .workout_list {
        background: #f5f5f5;
        border-bottom: 1px solid #eaeaea;
        margin: 0 -45px;
        padding: 40px 45px 20px 45px;
    }

    .smart_weeklist .add_btn {
        font-size: 13px;
        line-height: 16px;
    }

    .smart_weeklist .add_btn i {
        margin-right: 5px;
        font-size: 9px !important;
    }

    .smart_weeklist>ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .smart_weeklist>ul .week_info {
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        padding: 5px 10px 20px 10px;
    }

    .smart_weeklist>ul .week_info>li {
        padding: 5px;
        width: 25%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .smart_weeklist>ul .week_info li h6 {
        font-size: 9px;
        line-height: 11px;
        color: #121525;
        font-weight: 400;
        margin: 5px 0;
    }

    .smart_weeklist>ul .week_info li .box_item {
        flex-grow: 1;
        min-height: 70px;
        background: #f5f5f5;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3px;
    }

    .smart_weeklist>ul .week_info li .box_item p {
        font-size: 10px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-top: 3px;
    }

    .smart_weeklist>ul .week_info li .box_item ol {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .smart_weeklist>ul .week_info li .box_item ol li {
        list-style-type: inherit;
        font-size: 9px;
        line-height: 12px;
        padding: 3px 8px;
        font-weight: 400;
        color: #5a5a5a;
        background: #fff;
        border-radius: 9px;
    }

    .exercise_list {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .exercise_item .exercise_info ul {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .exercise_item .exercise_info ul li {
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 6px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        cursor: pointer;
    }

    .exercise_item .exercise_info ul li .video_area {
        width: 45%;
    }

    .video_wpr {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 4px;
        overflow: hidden;
    }

    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }

    .exercise_item .exercise_info ul li .info_wpr {
        width: calc(55% - 15px);
    }

    .exercise_item .exercise_info ul li .info_wpr h4 {
        font-size: 14px;
        line-height: 19px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .exercise_item .exercise_info ul li .info_wpr h6 {
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 5px 0;
    }

    .week_days_list {
        background: #fff;
        position: absolute;
        width: 250px;
        left: 50%;
        top: 100%;
        right: auto;
        transform: translateX(-50%);
        box-shadow: 5px 0 50px rgba(0, 0, 0, .1);
        border-radius: 8px;
        padding: 10px;
        z-index: 4;
        display: none;
        justify-content: center;
        flex-wrap: wrap;
        gap: 3px;
        margin-top: 10px;
    }

    .week_days_list.active {
        display: flex;
    }

    .week_days_list label .item_wpr {
        width: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 0;
        padding: 6px 4px;
        background: transparent;
        border: 1px solid transparent;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;
    }

    .week_days_list label span {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
    }

    .week_days_list label .tick_box {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 1px solid #cdcdcd;
        position: relative;
        margin-top: 7px;
    }

    .week_days_list label .tick_box:after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        background-color: #5a5a5a;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .week_days_list label.active .item_wpr {
        background: #fbfbfb;
        border: 1px solid #d4d4d4;
    }

    .week_days_list label.active .item_wpr .tick_box:after {
        transform: scale(1);
    }

    .week_days_list .action_wpr {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0;
    }

    .week_days_list .action_wpr .okay_btn {
        padding: 5px 15px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #fff;
        background: #2f7eed;
        border-radius: 3px;
        cursor: pointer;
    }

    .week_days_list .action_wpr .okay_btn i,
    .week_days_list .action_wpr .okay_btn i.fa-spin {
        color: #FFF;
    }

    .week_days_list .action_wpr .cancel_btn {
        padding: 5px 15px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        background: transparent;
        border-radius: 3px;
        cursor: pointer;
        border: 1px solid #DBDDE1;
    }

    .empty_area {
        border-radius: 10px;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .empty_area h4 {
        font-family: 'Architects Daughter', cursive;
        font-size: 16px;
        line-height: 35px;
        font-weight: 300;
        color: #999;
        max-width: 100%;
        position: relative;
        padding-bottom: 20px;
    }

    .quick_info {
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        width: 180px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: auto;
        bottom: 100%;
        transform: translateX(-50%);
        display: none;
        margin-bottom: 10px;
        font-size: 10px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
    }

    .quick_info:after {
        content: "";
        position: absolute;
        bottom: -6px;
        top: auto;
        left: calc(50% - 5px);
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }

    .week_list li .week_panel ul li .box_item p:hover .quick_info,
    .week_list li .week_panel ul li .box_item ol>li>label:hover .quick_info {
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }

    :deep(.addblock_section .quote_wpr) {
        padding: 15px 30px;
    }
    :deep(.week-workout-label .workout-name){
        display: flex;
        padding: 5px 8px 5px 10px;
    }
    :deep(.week-workout-label .workout-name label) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 60px;
        font-size: 11px;
        line-height: 14px;
    }
    :deep(.week-workout-label .workout-name time){
        text-transform: lowercase;
        padding-left: 3px;
        color: #2f7eed;
    }

    :deep(.week_list li .week_panel > ul > li.has-error) {
        border-top: 1px solid #eb1414;
        border-left: 1px solid #eb1414;
        border-color: #eb1414;
    }

    :deep(.week_list li .week_panel > ul > li.has-error + li.has-error) {
        border-left: 0px;
    }
    :deep(.week_list li .week_panel > ul > li.has-error > h5) {
        border-color: #eb1414;
        background: #eb141420;
    }

    :deep(.week_list li .week_panel > ul > li.has-error p.day-workout-error-message) {
        color: #eb1414;
        text-align: center;
    }

    @keyframes smoothMove {
        0% {
            bottom: 30%;
            opacity: 0;
        }

        10% {
            bottom: 30%;
            opacity: 0.5;
        }

        100% {
            bottom: 100%;
            opacity: 1;
        }
    }
    @media(max-width: 1499px){
        .week_list li .week_panel>ul>li{
            width: 20%;
        }
        .week_list li .week_panel>ul.narrow>li{
            width: 33.333%;
        }
    }
    @media(max-width: 1199px) {
        .info_bar {
            margin: 20px 10px;
        }

        .cover_type {
            margin: 15px -25px;
        }

        .week_list li .week_panel>ul>li{
            width: 25%;
        }
        .week_list li .week_panel>ul.narrow>li{
            width: 50%;
        }
    }

    @media(max-width: 991px) {
        .prog_edit {
            padding: 20px;
        }
        .week_list li .week_panel>ul>li, .week_list li .week_panel>ul.narrow>li{
            width: 25%;
            min-width: auto;
        }
    }

    @media(max-width: 767px) {

        .category_bar input,
        .category_bar .quest_title {
            font-size: 13px;
        }
        .week_list li .week_panel>ul>li, .week_list li .week_panel>ul.narrow>li{
            width: 33.333%;
        }
    }

    @media(max-width: 599px){
        .week_list li .week_panel>ul>li, .week_list li .week_panel>ul.narrow>li{
            width: 50%;
        }
    }

    @media(max-width: 499px) {
        .day_type li .day_wpr h4 {
            font-size: 11px;
        }

        .sqr_input {
            width: 20px;
            height: 20px;
            font-size: 10px;
        }

        :deep(.dp__main .dp__input) {
            font-size: 10px;
            padding: 0 0 0 25px !important;
        }

        :deep(.dp__main .dp__input_icons) {
            width: 12px;
        }

        .day_type li .day_wpr label {
            width: 40px;
            min-width: auto;
            flex-shrink: 0;
        }

        .time_listing li {
            width: 25%;
        }

        .circumference_list li {
            width: 100%;
        }

        .custom_list li {
            padding: 15px;
        }

        .custom_list li .section_wpr {
            padding: 10px 20px;
        }

        .category_bar .capsule_btn {
            padding: 0 10px 0 0;
        }

        .category_bar .add_btn {
            margin: 0 0 0 10px;
            padding-right: 10px;
        }

        .sub_categories {
            padding: 20px 15px;
        }

        .prog_edit {
            padding: 15px;
        }

        .cover_type {
            margin: 15px -15px;
        }
    }
    @media(max-width: 420px){
        .week_list li .week_panel>ul>li, .week_list li .week_panel>ul.narrow>li{
            width: 100%;
        }
    }
</style>